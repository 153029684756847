<template>
    <div class="weixin">
        <!-- 查詢 -->
        <section class="table-search">
            <div class="search-L">
                <el-select v-model="tableParams.popupStatus" placeholder="请选择状态" @change="reload" clearable>
                    <el-option v-for="item in ObjToOpt(popupStatusObj)" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="search-R">
                <el-button type="primary" @click="onAdd()">添加弹窗通知</el-button>
            </div>
        </section>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe :header-cell-style="{
            background: '#FAFAFA', color: '#000000'
        }">
            <el-table-column label="弹窗标题" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.popupTitle || "-" }}</span>
                </template>
            </el-table-column>
            <el-table-column label="弹出顺序" align="center" prop="topNo">
            </el-table-column>
            <el-table-column label="生效门店" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.shopNameList.join(',') }}</span>
                </template>
            </el-table-column>
            <el-table-column label="弹窗有效时间" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.startTime }}~{{ scope.row.endTime }}</span>
                </template>
            </el-table-column>
            <el-table-column label="弹窗频率" align="center">
                <template slot-scope="scope">
                    <span>{{ ['仅一次', '每日一次', '每次'][scope.row.frequency - 1] }}</span>
                </template>
            </el-table-column>
            <el-table-column label="状态" align="center">
                <template slot-scope="scope">
                    <span>{{
                        popupStatusObj[scope.row.popupStatus]
                        || "-" }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <template v-if="scope.row.popupStatus == 1">
                        <el-button type="text" size="medium" @click.stop="onEdit(scope.row, 1)">查看</el-button>
                        <el-button type="text" size="medium" @click.stop="onEdit(scope.row, 3)">弹窗顺序</el-button>
                        <el-button type="text" size="medium" @click.stop="updateStatus(scope.row, 0)">停用</el-button>
                    </template>
                    <template v-if="scope.row.popupStatus == 0">
                        <el-button type="text" size="medium" @click.stop="onEdit(scope.row, 2)">编辑</el-button>
                        <el-button type="text" size="medium" @click.stop="updateStatus(scope.row, 1)">启用</el-button>
                        <el-button type="text" size="medium" @click.stop="onDel(scope.row)">删除</el-button>
                    </template>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <section class="table-footer">
            <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
                :page-size="tableParams.pageSize" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
                :current-page.sync="currentPage" @current-change="onCurrentPage">
            </el-pagination>
        </section>
        <!-- 编辑弹框 -->
        <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="50%">
            <!-- 表单提交 -->
            <el-form :model="params" :rules="rules" ref="form-ref" label-width="140px" :disabled="dialogType == 1">
                <section class="form-main">

                    <el-form-item label="弹窗类型">
                        <el-radio-group v-model="params.popupType" @change="changePopupType">
                            <el-radio :label="1">图文</el-radio>
                            <el-radio :label="2">图片</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="适用类型">
                        <el-radio-group v-model="params.suitShopFlag">
                            <el-radio :label="0">所有门店</el-radio>
                            <el-radio :label="1">部分门店</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="适用门店" prop="shopIdList" v-if="params.suitShopFlag == 1"
                        :class="dialogType == 1 ? 'long-width' : ''">
                        <template v-if="dialogType != 1">
                            <el-select v-model="params.shopIdList" multiple collapse-tags placeholder="搜索选择门店" clearable
                                filterable remote reserve-keyword :remote-method="shopRemoteMethod">
                                <el-option v-for="item in shopList" :key="item.shopId" :label="item.shopName"
                                    :value="item.shopId">
                                </el-option>
                            </el-select>
                        </template>
                        <template v-else>
                            <el-tag v-for="item in params.popupShopVoList" :key="item.shopId" :label="item.shopName"
                                :value="item.shopId" style="margin-right: 10px">
                                {{ item.shopName }}
                            </el-tag>
                        </template>
                    </el-form-item>
                    <el-form-item label="弹窗标题" prop="popupTitle">
                        <el-input v-model.trim="params.popupTitle" autocomplete="off" maxlength="10"
                            placeholder="请输入弹窗名称"></el-input>
                    </el-form-item>

                    <div v-if="params.popupType == 2">
                        <el-form-item label="弹窗内容" prop="popupContentVoList">
                            <el-button type="primary" plain @click="addConfig">添加</el-button>
                        </el-form-item>
                        <div style="padding-left: 140px;">
                            <div class="config_item" v-for="(item, index) in params.popupContentVoList" :key="index">

                                <img :src="item.popupContent" alt="" srcset=""
                                    style="width:40px;height:40px;margin-right: 20px">
                                <p style="margin-right: 20px;">{{ item.contentTitle }}</p>
                                <template v-if="dialogType != 1">
                                    <i class="el-icon-edit" style="margin-right: 20px;"
                                        @click="editConfig(item, index)"></i>
                                    <i class="el-icon-delete" @click="delConfig(index)"></i>
                                </template>
                            </div>
                        </div>
                    </div>
                    <!-- 富文本 -->
                    <el-form-item label="弹窗内容" prop="popupContent" class="long-width" v-if="params.popupType == 1">
                        <template v-if="dialogType != 1">
                            <quill-editor v-model="params.popupContent" ref="myQuillEditor" :options="editorOption"
                                @change="onEditorChange($event)">
                            </quill-editor>
                        </template>
                        <template v-else>
                            <div class="popupContent" v-html="params.popupContent"></div>
                        </template>
                    </el-form-item>

                    <el-form-item label="点击后打开" prop="contentType" v-if="params.popupType == 1">
                        <el-select v-model="params.contentType" placeholder="请选择跳转类型">
                            <el-option v-for="item in ObjToOpt(openType)" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="公众号链接" prop="linkUrl" v-if="params.contentType == 3" :rules="{
                        required: params.contentType == 3, message: '请输入公众号链接', trigger: ['blur', 'change']
                    }">
                        <el-input v-model.trim="params.linkUrl" autocomplete="off" placeholder="请输入公众号链接"></el-input>
                    </el-form-item>
                    <el-form-item label="弹窗有效时间" prop="dateToDate">
                        <el-date-picker v-model="params.dateToDate" type="daterange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                    <div>
                        <el-form-item label="弹窗频率" prop="frequency">
                            <el-radio-group v-model="params.frequency">
                                <el-radio :label="1">仅一次</el-radio>
                                <el-radio :label="2">每日一次</el-radio>
                                <el-radio :label="3">每次</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>
                    <el-form-item label="状态" prop="popupStatus">
                        <el-radio-group v-model="params.popupStatus">
                            <el-radio :label="0">停用</el-radio>
                            <el-radio :label="1">启用</el-radio>
                        </el-radio-group>
                    </el-form-item>

                </section>
                <section class="form-footer">
                    <el-button type="primary" plain @click="onCancel('form-ref', 1)">取消</el-button>
                    <el-button type="primary" @click="onSubmit('form-ref', 1)">保存</el-button>
                </section>
            </el-form>
        </el-dialog>
        <!-- 编辑弹框 -->
        <el-dialog :title="'弹出顺序'" :visible.sync="sortShow" :close-on-click-modal="false" append-to-body width="30%">
            <!-- 表单提交 -->
            <el-form :model="params" :rules="rules" ref="sort_form-ref" label-width="140px">
                <section class="form-main">
                    <el-form-item label="排序值" prop="topNo">
                        <el-input type="number" v-model="params.topNo"
                            oninput="if(value.length>10)value=value.slice(0,10)" placeholder="请输入排序值" @keydown.native="(e) =>
                            (e.returnValue = ['e', 'E', '+', '-'].includes(
                                e.key
                            )
                                ? false
                                : e.returnValue)
                                ">
                        </el-input>
                    </el-form-item>

                </section>
                <section class="form-footer">
                    <el-button type="primary" plain @click="onCancel('sort_form-ref', 2)">取消</el-button>
                    <el-button type="primary" @click="onSubmit('sort_form-ref', 2)">保存</el-button>
                </section>
            </el-form>
        </el-dialog>
        <el-dialog :title="'添加弹窗内容'" :visible.sync="showConfigEdit" :close-on-click-modal="false" append-to-body
            width="40%" center @close="closeConfigDialog('popupContentForm')">
            <el-form :model="configInfo" :rules="rules" ref="popupContentForm" label-width="120px">
                <el-form-item label="通知标题" prop="contentTitle">
                    <el-input type="textarea" v-model="configInfo.contentTitle" placeholder="请输入通知标题"
                        show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="弹窗内容" prop="popupContent">
                    <div class="upload-btn" @click="onUpload(1, 1)">
                        <img v-if="configInfo.popupContent" :src="configInfo.popupContent" class="upload-img">
                        <i v-else class="el-icon-plus uploader-icon"></i>

                    </div>
                    <p style="font-size: 12px;color:#606266;width: 150%;">上传一张.jpg或.png格式图片，建议尺寸484px*236px</p>
                </el-form-item>
                <el-form-item label="点击后打开" prop="contentType">
                    <el-select v-model="configInfo.contentType" placeholder="请选择跳转类型">
                        <el-option v-for="item in ObjToOpt(openType)" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="公众号链接" prop="linkUrl" v-if="configInfo.contentType == 3" :rules="{
                    required: configInfo.contentType == 3, message: '请输入公众号链接', trigger: ['blur', 'change']
                }">
                    <el-input v-model.trim="configInfo.linkUrl" autocomplete="off" placeholder="请输入公众号链接"></el-input>
                </el-form-item>

                <div style="text-align: center;width: 100%;">
                    <el-button type="primary" @click="saveConfig('popupContentForm')">保存</el-button>
                </div>

            </el-form>
        </el-dialog>
        <!-- 图片上传组件 -->
        <UpLoad @fileIds="getFileIds" :maxlimit="maxlimit" ref="up" :fileType="fileType"></UpLoad>
    </div>
</template>

<script>
import {
    getTableAdd,
    getTableDel,
    getTableInfo,
    getTableList,
    getUserPopupList,
    updateSort,
    getTableEdit,
    updateStatus
} from "@/api/activity/popup";
import {
    getShopList,
} from "@/api/commercial/shop";
import { ObjToOpt } from "@/utils/utils";

import { popupStatusObj, openType } from "@/db/objs";
import { rules } from "@/db/rules";
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import UpLoad from "@/components/upload";
import {
    getFileList
} from "@/api/common";

import * as Quill from 'quill';
import ImageResize from 'quill-image-resize-module';
Quill.register('modules/imageResize', ImageResize);
export default {
    components: {
        quillEditor, UpLoad
    },
    data() {
        return {

            ObjToOpt,
            openType,
            popupStatusObj,
            currentPage: 1, // 当前页
            total: 0, // 总条数  
            tableData: [],//表格数据
            tableParams: {
                pageNum: 1,
                pageSize: 10,
                popupStatus: 1,
                shopId: "",//店铺ID
            },
            // 弹框
            editShow: false, //弹框开关
            editTitle: "添加弹窗通知",//弹框标题
            sortShow: false,//排序弹框
            // from表单
            rules,
            shopList: [],
            shopParams: {
                page: 1,
                size: 0,
                shopStatus: 1,
                keyWord: "",//查询关键字
            },
            params: {
                popupTitle: "",
                suitShopFlag: 0,
                frequency: 1,
                contentType: 0,
                popupType: 1,
                popupStatus: 0,
                shopId: "",
                popupContentVoList: [],
                popupShopVoList: [],
                dateToDate: []
            },
            editorOption: {
                modules: {
                    toolbar: {
                        container: [
                            ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                            ['blockquote', 'code-block'], // 引用  代码块
                            [{ header: 1 }, { header: 2 }], // 1、2 级标题
                            [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                            [{ script: 'sub' }, { script: 'super' }], // 上标/下标
                            [{ indent: '-1' }, { indent: '+1' }], // 缩进
                            [{ direction: 'rtl' }], // 文本方向
                            [{ size: ['12', '14', '16', '18', '20', '22', '24', '28', '32', '36'] }], // 字体大小
                            [{ header: [1, 2, 3, 4, 5] }], // 标题
                            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                            [{ align: [] }], // 对齐方式
                            ['clean'], // 清除文本格式
                            ['image', 'video'] // 链接、图片、视频
                        ],
                        handlers: {
                            'image': (value) => {
                                if (value) {
                                    this.onUpload(1, 3);
                                } else {
                                    this.quill.format('image', false)
                                }
                            }
                        }
                    },
                    // 调整图片大小
                    imageResize: {
                        displayStyles: {
                            backgroundColor: 'black',
                            border: 'none',
                            color: 'white'
                        },
                        modules: ['Resize', 'DisplaySize', 'Toolbar']
                    }
                },
                placeholder: '请填写正文'
            },
            configInfo: {
                contentType: 0,
                popupContent: '',
                contentTitle: ''
            },
            showConfigEdit: false,
            fileList: [],//图片列表
            fileType: 1,
            dialogType: 1,//，1查看，2编辑,3新增

        }
    },
    created() {
        let merchantShopInfo = JSON.parse(window.localStorage.getItem('playOne-store-merchantShopInfo'));
        if (merchantShopInfo) {
            Object.assign(this.tableParams, merchantShopInfo);
            // Object.assign(this.params, merchantShopInfo);
        }
    },
    computed: {
        upBtnShow() {
            if (this.fileList && this.fileList.length > 5) {
                return false
            } else if (!this.fileList || this.fileList.length < 5) {
                return true
            }
        },
        maxlimit() {
            if (this.fileList && this.fileList.length > 0) {
                return 5 - this.fileList.length
            } else {
                return 5
            }
        }
    },
    mounted() {
        this._getShopList()

        this.getTableList();// 【请求】表格数据
    },
    methods: {
        reset() {
            this.params = {
                popupTitle: "",
                frequency: 1,
                contentType: 0,
                suitShopFlag: 0,
                dateToDate: [],
                popupType: 1,
                popupStatus: 0,
                popupContentVoList: []
            }
        },
        // 选择已经启用的店铺作为商品可领取店铺
        _getShopList() {

            getShopList(this.shopParams).then(res => {
                if (res.isSuccess == 'yes') {
                    this.shopList = res.data.list
                }
            })
        },
        // 门店搜索
        shopRemoteMethod(query) {
            this.shopParams.keyWord = query;
            if (query !== "") {
                this.loading = true;
                this._getShopList();
            } else {
                this.shopList = [];
            }
        },
        // 【请求】表格数据
        getTableList() {
            let data = this.tableParams;
            getTableList(data).then((res) => {
                if (res.isSuccess == "yes") {
                    this.tableData = res.data.list;
                    this.total = res.data.total;
                }
            });
        },



        // 【请求】列表详情
        getTableInfo(row) {
            getTableInfo({ popupId: row.popupId }).then((res) => {
                if (res.isSuccess == "yes") {
                    this.params = res.data;
                    this.$set(this.params, 'dateToDate', [this.params.startTime, this.params.endTime])
                }
            });
        },

        // 【请求】添加
        getTableAdd() {
            this.params.shopId = this.tableParams.shopId;

            getTableAdd(this.params).then((res) => {
                if (res.isSuccess == "yes") {
                    this.$message({
                        message: res.message,
                        type: "success",
                    });
                    this.editShow = false;
                    this.reload();// 【请求】表格数据
                }
            });
        },

        // 【请求】编辑
        getTableEdit() {
            let data = this.params;
            getTableEdit(data).then((res) => {
                if (res.isSuccess == "yes") {
                    this.$message({
                        message: res.message,
                        type: "success",
                    });
                    this.editShow = false;
                    this.getTableList();// 【请求】表格数据
                }
            });
        },

        // 【请求】获取二维码信息

        // 【监听】表格模糊查询
        kwChange: _.debounce(function () {
            this.reload();
        }, 500),

        // 【监听】表格重载
        reload() {
            this.tableParams.pageNum = 1;
            this.currentPage = 1;
            this.getTableList();
        },

        // 【监听】表格分页点击
        onCurrentPage(page) {
            this.tableParams.pageNum = page;
            this.getTableList();
        },

        // 【监听】表格条数点击
        onSizeChange(pageSize) {
            this.tableParams.pageSize = pageSize;
            this.reload();
        },

        // 【监听】启用/停用
        updateStatus(row, type) {
            let data = {
                shopId: this.tableParams.shopId,
                popupId: row.popupId,
                popupStatus: type
            };
            const title = type == 1 ? '启用' : '停用'
            const tip = type == 1 ? '是否确认启用该通知?' : '是否确认停用该通知?'
            this.$confirm(tip, title, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            }).then(() => {
                updateStatus(data).then(res => {
                    if (res.isSuccess == "yes") {
                        this.$message({
                            message: res.message,
                            type: "success",
                        });
                        this.getTableList();
                    }
                })
            });
        },




        // 【监听】编辑
        onAdd() {
            this.editShow = true;
            this.editTitle = "添加弹窗通知";
            this.dialogType = 3
            this.reset()

            this.$nextTick(() => {
                this.$refs["form-ref"].clearValidate();
            });
        },

        // 【监听】编辑
        onEdit(row, type) {
            this.dialogType = type
            switch (type) {
                case 1:
                    this.editShow = true;
                    this.editTitle = "查看弹窗通知";
                    break;
                case 2:
                    this.editShow = true;
                    this.editTitle = "编辑弹窗通知";
                    break;
                case 3:
                    this.sortShow = true;
                    this.editTitle = "弹窗顺序";
                    this.$nextTick(() => {
                        this.$refs["sort_form-ref"].clearValidate();
                    });
                    break;
            }
            this.getTableInfo(row);

        },


        onCancel(formName, type) {
            if (type == 1) {
                this.editShow = false;
            } else {
                this.sortShow = false;
            }
            this.$nextTick(() => {
                this.$refs[formName].resetFields();
                this.$refs[formName].clearValidate();
            });
        },
        // 更新弹窗排序
        updateSort() {
            updateSort(this.params).then(res => {
                if (res.isSuccess == "yes") {
                    this.$message({
                        message: res.message,
                        type: "success",
                    });
                    this.getTableList();
                    this.sortShow = false;
                }
            })

        },
        // 【监听】表单提交
        onSubmit(formName, type) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (type == 1) {
                        this.params.startTime = this.params.dateToDate[0]
                        this.params.endTime = this.params.dateToDate[1]
                        if (this.params.popupId) {
                            this.getTableEdit();
                        } else {
                            this.getTableAdd();
                        }
                    } else {
                        this.updateSort();
                    }


                } else {
                    return false;
                }
            });
        },

        // 【监听】删除用户
        onDel(row) {

            this.$confirm('是否确定删除该通知?', '删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                getTableDel({ popupId: row.popupId }).then(res => {
                    if (res.isSuccess == "yes") {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.reload();
                    }
                })
            });
        },
        // 【监听】富文本内容改变事件
        onEditorChange({ quill, html, text }) {
            this.params.goodsDetail = html
        },
        addConfig() {
            this.showConfigEdit = true
        },
        editConfig(item, index) {
            this.configInfo = JSON.parse(JSON.stringify(item))
            this.configInfo.index = index
            this.showConfigEdit = true
        },
        // 点击添加弹窗配置
        saveConfig(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.configInfo.index != null) {
                        this.params.popupContentVoList[this.configInfo.index] = this.configInfo
                    } else {
                        this.params.popupContentVoList.push(this.configInfo)
                    }

                    this.configInfo = {
                        contentType: 0,
                        popupContent: '',
                        contentTitle: ''
                    }

                    this.$refs[formName].clearValidate();
                    this.showConfigEdit = false;

                } else {
                    return false;
                }
            })
        },
        closeConfigDialog(formName) {
            this.configInfo = {
                contentType: 0,
                popupContent: '',
                contentTitle: ''
            }
            this.$refs[formName].clearValidate();
            this.showConfigEdit = false;
        },
        delConfig(index) {
            this.params.popupContentVoList.splice(index, 1)
        },
        // 【监听】文件上传
        onUpload(fileType, upType) {
            this.fileType = fileType;
            this.upType = upType;
            this.$refs.up.onUpload();
        },
        cutUrl(url) {
            let index = url.indexOf('?');
            return url.slice(0, index)
        },
        // 【监听】获取上传文件ID集合
        getFileIds(fileIds) {
            let data = {
                fileIds
            }
            getFileList(data).then(res => {
                if (res.isSuccess == "yes") {
                    // 缩略图
                    if (this.upType == 1) {
                        let url = res.data[0].url;
                        this.configInfo.popupContent = url.split('?')[0];
                        this.$refs.popupContentForm.validateField('popupContent')
                    } // 主图
                    else if (this.upType == 2) {
                        this.params.imageIds = fileIds;
                        this.fileList = [...this.fileList, ...res.data]
                    }
                    // 富文本
                    else if (this.upType == 3) {
                        let url = this.cutUrl(res.data[0].url);//获取图片路径
                        let quill = this.$refs.myQuillEditor.quill;//获取光标信息
                        let length = quill.getSelection().index;//获取当前光标位置
                        quill.insertEmbed(length, 'image', url);//在光标处插入图片
                        quill.setSelection(length + 1);//将光标移至图片后
                    }
                    this.$forceUpdate();
                }
            })
        },
        changePopupType(e) {
            this.params.popupContentVoList = []
        },

    }
}
</script>

<style lang="scss" scoped>
.upload-btn {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    width: 1rem;
    height: 1rem;
}

.upload-btn:hover {
    border-color: #409EFF;
}

::v-deep .el-radio-group {
    width: 300px;
}

.uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 1rem;
    height: 1rem;
    line-height: 1rem;
    text-align: center;
}

.popupContent {
    width: 100%;
    padding: 10px;
    border: 1px solid #eee;
}

.upload-img {
    display: inline-block;
    border: 1px dashed #d9d9d9;
    margin: 0 .1rem .1rem 0;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    position: relative;

    >img {
        display: inline-block;
        width: 100%;
        height: 100%;
    }
}

.tip {
    color: red;
    display: flex;
}

.imgs {
    display: flex;
    flex-wrap: wrap;
}

.img-del {
    width: 1rem;
    height: 1rem;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    margin: auto;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .5);
    visibility: hidden;

    >.el-icon-delete {
        font-size: 28px;
        color: #fff;
    }
}

.upload-img:hover .img-del {
    visibility: visible;
}

.weixin {
    padding: .15rem;
}

.table-footer {
    margin-top: .1rem;
    display: flex;
    justify-content: flex-end;
}

.table-search {
    display: flex;
    justify-content: space-between;
    margin-bottom: .15rem;
}

.search-L {
    display: flex;
}

.form-footer {
    display: flex;
    justify-content: flex-end;
}

.code {
    display: flex;
    flex-direction: column;
    align-items: center;

    >p {
        width: 100%;
        display: flex;
    }
}

.user-item {
    margin-right: .15rem;
    margin-bottom: .15rem;
    display: inline-flex;
    align-items: center;
}

.user-img {
    display: inline-flex;
    width: 14px;
    border-radius: 50%;
}

.config_item {

    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.del-icon {
    font-size: .16rem;
    cursor: pointer;
    margin-left: .05rem;
}
</style>
<style>
.long-width .el-form-item__content {
    width: 6rem;
}

.ql-container {
    min-height: 3rem;
}
</style>